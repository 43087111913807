const INFO = {
	main: {
		title: "Spencer Blake Software Engineer",
		name: "Spencer Blake",
		email: "spencerblake91@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/yo-no",
		linkedin: "https://www.linkedin.com/in/spencerwblake/",
	},

	homepage: {
		title: "Full-stack software engineer busy building the web of the future.",
		description:
			"I am a full stack developer based in Chicago specializing in React, Next, Node, and Java. I have experience in building scalable, secure and reliable web applications across frameworks and technologies. I am always looking for new challenges and opportunities to grow as a developer!",
	},

	about: {
		title: "I’m Spencer! Nice to meet you!",
		description:
			"I'm a full stack software engineer born and raised in Chicago, IL. I'm passionate about pushing the boundaries of what React can do and creating UI/UX that leaves a lasting impression. When I'm not busy submitting pull requests, I'm a part-time musician and a full time dog dad.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Playbook",
			description:
				"Playbook is a React Component library for the Stats Perform suite of products.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://playbook.statsperform.com/",
		},

		{
			title: "Joseph Chilliams",
			description:
				"Home page for the Chicago rapper Joseph Chilliams",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://josephchilliams.com",
		},

		{
			title: "Console.lol()",
			description:
				"A YouTube Channel to help coders such as myself prepare for technical interviews.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "Coming Soon",
			link: "/",
		},
	],
};

export default INFO;
